body,
html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}
@font-face {
  font-family: Jannat-bold;
  src: url("../fonts/a-jannat-lt-bold.ttf") format("truetype");
}

@font-face {
  font-family: Jannat;
  src: url("../fonts/a-jannat-lt.ttf") format("truetype");
}

@font-face {
  font-family: Dubai-bold;
  src: url("../fonts/Dubai-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Dubai;
  src: url("../fonts/Dubai-Regular.ttf") format("truetype");
}
body,
p {
  color: rgba(105 123 140);
  font: 400 0.875rem/1.375rem Jannat, sans-serif;
}

.p-large {
  font: 400 1rem/1.5rem Jannat, sans-serif;
}

.p-small {
  font: 400 0.75rem/1.25rem Jannat, sans-serif;
}

.p-heading {
  margin-bottom: 1.875rem;
}

.col-lg-4 {
  padding-bottom: 4.5rem;
}

.li-space-lg li {
  margin-bottom: 0.25rem;
}

.indent {
  padding-left: 1.25rem;
}

h1 {
  color: #393939;
  font: 700 3rem/3.5rem Jannat, sans-serif;
}

h2 {
  color: rgba(60 180 229);
  font: 700 2.25rem/2.75rem Jannat, sans-serif;
}

h3 {
  color: #393939;
  font: 700 1.75rem/2rem Jannat, sans-serif;
}

h4 {
  color: rgba(62 77 91);
  font: 700 1.2rem/1.875rem Jannat, sans-serif;
}

h5 {
  color: #393939;
  font: 700 1.125rem/1.625rem Jannat, sans-serif;
}

h6 {
  color: #393939;
  font: 700 1rem/1.5rem Jannat, sans-serif;
}

a {
  color: rgba(60 180 229);
  text-decoration: underline;
}

a:hover {
  color: rgba(60 180 229);
  text-decoration: underline;
}

a.turquoise {
  color: rgba(60 180 229);
}

a.white {
  color: #fff;
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ipad-show {
  display: none;
}

.carousel-control-prev {
  top: 90px;
  /* top: calc(50% - 85px); */
  bottom: auto;
  /* transform: translateY(calc(-50% + 43px)); */
  left: -25px;
}

.carousel-control-next {
  top: 90px;
  /* top: calc(50% - 85px); */
  bottom: auto;
  /* transform: translateY(calc(-50% + 43px)); */
  right: -25px;
}

.carousel-indicators {
  margin-right: 0;
  margin-left: 0;
  padding: 0;
}
.carousel-indicators li {
  position: relative;
  width: 10px;
  box-sizing: inherit;
}
.carousel-indicators li:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 16px;
  background: rgba(169, 175, 187, 1);
}
.carousel-indicators li.active:before {
  background: rgba(108, 206, 245, 1);
}

.mb-30 {
  margin-bottom: 30px;
}

.list {
  list-style: none;
  padding-left: 0;
}
.list li {
  position: relative;
  padding: 8px 0px 8px 15px;
}
.list li:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 16px;
  background: rgba(169, 175, 187, 1);
}

.box {
  margin-bottom: 70px;
  text-align: center;
}
.box h4 {
  margin-bottom: 5px;
}

.turquoise {
  color: rgba(60 180 229);
}

.brand-icons {
  padding: 2px 10px;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  transition: all 0.2s ease-out;
}

.brand-icons:hover {
  border-color: rgba(60 180 229);
  background-color: rgba(60 180 229);
  box-shadow: 0 0 6px rgb(98 195 238);
}

.apple-icon,
.google-icon {
  fill: #3cb4e5;
}
.brand-icons:hover .apple-icon,
.brand-icons:hover .google-icon {
  fill: #fff;
}

.btn-solid-lg {
  display: inline-block;
  padding: 1.375rem 2.625rem;
  border: 1px solid rgba(60 180 229);
  border-radius: 0.5rem;
  background-color: rgba(60 180 229);
  color: #fff;
  font: 700 0.75rem/0 Jannat, sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-solid-lg:hover {
  background-color: transparent;
  color: #00bfd8;
  text-decoration: none;
}

/**************************/
/*     03. Navigation     */
/**************************/
.navbar-custom {
  background-color: #fff;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  font: 600 0.875rem/0.875rem Jannat, sans-serif;
  transition: all 0.2s;
}

.navbar-custom .navbar-brand.logo-image {
  width: 8rem;
  height: 4.25rem;
  cursor: pointer;
}

.navbar-custom .navbar-brand.logo-text {
  font: 600 2rem/1.5rem Jannat, sans-serif;
  color: #393939;
  text-decoration: none;
}

.navbar-custom .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.navbar-custom .nav-item .nav-link {
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  color: #393939;
  text-decoration: none;
  transition: all 0.2s ease;
}

.active {
  cursor: default !important;
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
  color: rgba(60 180 229);
  background-color: rgb(255 255 255);
  border-radius: 0.2rem;
  opacity: 1;
}

.withIcon {
  display: flex;
  align-items: center;
}

.navbar-custom .nav-item .nav-link:hover {
  z-index: 1;
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover > .dropdown-menu {
  display: block; /* this makes the dropdown menu stay open while hovering it */
  min-width: auto;
  animation: fadeDropdown 0.2s; /* required for the fade animation */
}

.navbar-custom .dropdown-toggle:focus {
  /* removes dropdown outline on focus */
  outline: 0;
}

.navbar-custom .dropdown-menu {
  border: none;
  border-radius: 0.25rem;
  background-color: #fff;
  top: 2.35rem;
  right: 0;
  padding: 0;
  width: fit-content;
}

.navbar-custom .dropdown-item {
  color: #393939;
  text-decoration: none;
  padding: 0.25rem;
  display: flex;
}
.dropdown-item.active {
  background-color: unset;
}

.navbar-custom .dropdown-item:hover {
  background-color: #fff;
  border-radius: 0.5rem;
}

.item-text {
  padding: 0.5rem 1rem;
  width: 100%;
  font-size: 0.8rem;
}

.navbar-custom .dropdown-item:hover .item-text {
  color: #00bfd8;
}

.navbar-custom .dropdown-item.active .item-text {
  background: rgb(241 242 246);
  color: rgb(0 0 0);
}

.navbar-custom .dropdown-items-divide-hr {
  width: 100%;
  height: 1px;
  margin: 0.75rem auto 0.725rem auto;
  border: none;
  background-color: #c4d8dc;
  opacity: 0.2;
}
/* end of dropdown menu */

.navbar-custom .navbar-toggler {
  border: none;
  color: #393939;
  font-size: 2rem;
}

.navbar-custom
  button[aria-expanded="false"]
  .navbar-toggler-awesome.fas.fa-times {
  display: none;
}

.navbar-custom
  button[aria-expanded="false"]
  .navbar-toggler-awesome.fas.fa-bars {
  display: inline-block;
}

.navbar-custom
  button[aria-expanded="true"]
  .navbar-toggler-awesome.fas.fa-bars {
  display: none;
}

.navbar-custom
  button[aria-expanded="true"]
  .navbar-toggler-awesome.fas.fa-times {
  display: inline-block;
  margin-right: 0.125rem;
}

/*********************/
/*    04. Header     */
/*********************/
.header {
  background-color: #fff;
}

.header .header-content {
  padding-top: 8rem;
  padding-bottom: 4rem;
  text-align: center;
}

.header .text-container {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header h1 {
  margin-bottom: 1.125rem;
  font-size: 2rem;
  line-height: 3.125rem;
}

.header .p-large {
  margin-bottom: 1rem;
}

/************************/
/*     06. Services     */
/************************/

.cards-1 {
  padding-top: 6.5rem;
  padding-bottom: 1.625rem;
  text-align: center;
}

.cards-1 h2 {
  margin-bottom: 1rem;
}

.cards-1 .card {
  max-width: 21rem;
  margin-right: auto;
  margin-left: auto;
  padding: 3.25rem 2rem 2rem 2rem;
  border: 1px solid #c4d8dc;
  border-radius: 0.5rem;
  background: transparent;
  height: 100%;
}

.cards-1 .card-image {
  width: 6rem;
  height: 6rem;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
}

.cards-1 .card-title {
  margin-bottom: 0.875rem;
}

.cards-1 .card-body {
  padding: 0;
}

/*********************/
/*     12. Video     */
/*********************/
.basic-3 {
  padding-top: 0.875rem;
  padding-bottom: 6.125rem;
}

.basic-3 h2 {
  margin-bottom: 3rem;
  text-align: center;
}

.basic-3 .image-container {
  margin-bottom: 2.25rem;
}

.basic-3 .image-container img {
  border-radius: 0.5rem;
}

.basic-3 .video-wrapper {
  position: relative;
}

/* Video Play Button */
.basic-3 .video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  display: block;
  box-sizing: content-box;
  width: 2rem;
  height: 2.75rem;
  padding: 1.125rem 1.25rem 1.125rem 1.75rem;
  border-radius: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.basic-3 .video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  display: block;
  width: 4.75rem;
  height: 4.75rem;
  border-radius: 50%;
  background: #00bfd8;
  animation: pulse-border 1500ms ease-out infinite;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.basic-3 .video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  display: block;
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 50%;
  background: #00bfd8;
  transition: all 200ms;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.basic-3 .video-play-button span {
  position: relative;
  display: block;
  z-index: 3;
  top: 0.375rem;
  left: 0.25rem;
  width: 0;
  height: 0;
  border-left: 1.625rem solid #fff;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
/* end of video play button */

.basic-3 p {
  text-align: center;
}

/**********************/
/*     16. Footer     */
/**********************/
.footer {
  padding-top: 4.625rem;
}

.footer .footer-col {
  margin-bottom: 2.25rem;
}

.footer h4 {
  margin-bottom: 0.7rem;
  font-size: 1rem;
}

.footer .footer-col.middle {
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .footer-col.middle img {
  width: 60%;
  max-width: 150px;
}

.footer .footer-col.last {
  width: 85%;
}

.footer .list-unstyled .fas {
  color: #00bfd8;
  font-size: 0.5rem;
  line-height: 1.375rem;
}

.footer .list-unstyled .media-body {
  margin-left: 0.625rem;
}

.footer .fa-stack {
  margin-bottom: 0.75rem;
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

.footer .fa-stack .fa-stack-1x {
  color: #fff;
  transition: all 0.2s ease;
}

.footer .fa-stack .fa-stack-2x {
  color: #00bfd8;
  transition: all 0.2s ease;
}

.footer .fa-stack:hover .fa-stack-1x {
  color: #fff;
}

.footer .fa-stack:hover .fa-stack-2x {
  color: #00a7bd;
}

/*************************/
/*     17. Copyright     */
/*************************/
.copyright {
  padding-bottom: 0.375rem;
  text-align: center;
}

.copyright .p-small {
  border-top: 1px solid #c4d8dc;
  opacity: 0.7;
}

/**********************************/
/*     18. Back To Top Button     */
/**********************************/
a.back-to-top {
  position: fixed;
  z-index: 999;
  right: 0.75rem;
  bottom: 3rem;
  cursor: pointer;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 1.875rem;
  background: rgb(60 180 229) url("../images/up-arrow.png") no-repeat center 47%;
  background-size: 1.125rem 1.125rem;
  text-indent: -9999px;
}

a:hover.back-to-top {
  background-color: rgb(73 202 255);
}

/*****************************/
/*     21. Media Queries     */
/*****************************/

/* Min-width width 768px */
@media (min-width: 768px) {
  /* General Styles */
  .p-heading {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }
  #welcomeText .p-large {
    font-size: 0.8rem;
  }
  #welcomeText h4 {
    font-size: 1rem;
  }
  #welcomeText h1 {
    font-size: 1.5rem;
  }
  /* end of general styles */

  /* Header */

  .header .img-fluid {
    display: none;
  }
  #teachersHeader .img-fluid {
    display: block;
  }

  /* end of header */

  /* Video */
  .basic-3 {
    padding-bottom: 0;
  }
  .basic-3 h2 {
    margin-bottom: 1.5rem;
  }
  .basic-3 .image-container {
    margin-bottom: 1.25rem;
  }
  .basic-3 p {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }
  /* end of video */

  /* footer */
  .footer .footer-col.last {
    width: 100%;
  }
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  #welcomeText .p-large {
    font-size: 1rem;
  }
  #welcomeText h4 {
    font-size: 1.35rem;
  }
  #welcomeText h1 {
    font-size: 2rem;
  }
  /* Navigation */
  .navbar-custom {
    box-shadow: none;
    background: transparent;
  }

  .navbar-custom .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }
  .navbar-custom .nav-item {
    padding: 0 0.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-custom .nav-item .nav-link {
    padding: 0.25rem;
    color: #fff;
    opacity: 0.8;
  }
  .navbar-custom .nav-item2 .nav-link2 {
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    color: #393939;
    opacity: 0.8;
  }

  .navbar-custom .nav-item .nav-link:hover,
  .navbar-custom .nav-item .nav-link.active {
    color: rgba(60 180 229);
    background-color: rgb(255 255 255);
    border-radius: 0.2rem;
    opacity: 1;
  }

  .navbar-custom .nav-item2 .nav-link2:hover,
  .navbar-custom .nav-item2 .nav-link2.active {
    color: #393939;
    opacity: 1;
  }

  .navbar-custom.top-nav-collapse {
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link {
    color: #393939;
    opacity: 1;
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
  .navbar-custom.top-nav-collapse .nav-item .nav-link.active {
    background-color: rgba(60 180 229);
    color: rgb(255 255 255);
    border-radius: 0.2rem;
  }

  .navbar-custom .dropdown-menu {
    border-radius: 0.25rem;
  }

  .navbar-custom.top-nav-collapse .dropdown-menu {
    box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.02);
  }

  .navbar-custom .dropdown-items-divide-hr {
    width: 84%;
  }

  .navbar-custom .social-icons {
    display: block;
  }

  .navbar-custom .fa-stack {
    margin-bottom: 0.1875rem;
    margin-left: 0.25rem;
    font-size: 0.75rem;
  }

  .navbar-custom .fa-stack-2x {
    color: rgb(255 255 255);
    transition: all 0.2s ease;
  }
  .navbar-custom div div {
    color: rgb(255 255 255);
    border: 1px solid rgb(255 255 255);
    transition: all 0.2s ease;
  }

  .navbar-custom .fa-stack-1x {
    color: rgba(60, 180, 229, 1);
    transition: all 0.2s ease;
  }

  .navbar-custom .fa-stack:hover .fa-stack-2x {
    color: #fff;
  }

  .navbar-custom .fa-stack:hover .fa-stack-1x {
    color: #00bfd8;
  }

  .navbar-custom.top-nav-collapse .fa-stack-2x {
    color: rgba(79 189 234);
  }
  .navbar-custom.top-nav-collapse div div {
    color: rgba(79 189 234);
    border: 1px solid rgba(79 189 234);
  }

  .navbar-custom.top-nav-collapse .fa-stack-1x {
    color: #fff;
  }

  .navbar-custom.top-nav-collapse .fa-stack:hover .fa-stack-2x {
    color: #00a7bd;
  }

  .navbar-custom.top-nav-collapse .fa-stack:hover .fa-stack-1x {
    color: #fff;
  }
  /* end of navigation */

  /* General Styles */
  .p-heading {
    width: 65%;
  }
  /* end of general styles */

  /* Header */
  .header .img-fluid {
    display: block;
  }
  .header .text-container {
    margin-top: 3rem;
    margin-bottom: 0;
  }
  /* end of header */

  /* Services */
  .cards-1 .card {
    display: inline-block;
    max-width: 17.125rem;
    vertical-align: top;
  }

  .cards-1 .col-lg-12 div.card:nth-child(3n + 2) {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  /* end of services */

  /* Video */
  .basic-3 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .basic-3 p {
    width: 65%;
  }
  /* end of video */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* General Styles */
  .p-heading {
    width: 55%;
  }
  /* end of general styles */

  /* Header */

  .header .text-container {
    margin-top: 5.375rem;
    margin-left: 1rem;
    margin-right: 2rem;
  }

  #testimonials .text-container {
    height: 450px;
  }

  .header .image-container {
    margin-left: 2rem;
    margin-right: 1rem;
  }
  /* end of header */

  /* Services */
  .cards-1 .card {
    max-width: 21rem;
  }

  .cards-1 .col-lg-12 div.card:nth-child(3n + 2) {
    margin-right: 2.875rem;
    margin-left: 2.875rem;
  }

  /* Video */
  .basic-3 p {
    width: 55%;
  }
  /* end of video */
}

/* end of min-width width 1200px */

@media (min-width: 1400px) {
  /* Header */
  #welcomeText h4 {
    font-size: 1.35rem;
  }
  #welcomeText h1 {
    font-size: 2.2rem;
  }
  .btn-solid-lg {
    font: 700 0.85rem/0 Jannat, sans-serif;
  }
  p {
    font: 400 1rem/1.5rem Jannat, sans-serif;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ipad-show {
    display: block;
  }

  .ipad-hide {
    display: none;
  }
  .header .text-container {
    margin-top: 4rem;
  }
  #testimonials .text-container {
    height: 350px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    top: 54px;
  }
  #teachersFeatures .container {
    max-width: 100%;
  }
}

@media (max-width: 1024px) {
  .home-header {
    margin-bottom: 0;
    min-height: auto;
    height: auto !important;
  }
}

@media (max-width: 991px) {
  #appText h2 {
    font-size: 1.75rem;
    margin-top: 3rem;
  }
  #appText p {
    font-size: 0.8rem;
  }
  /* Testimonials */
  #testimonials {
    background: url("../images/testimonials_doodle_bg.svg") right center / cover
      no-repeat !important;
  }
  /* end of Testimonials */
}

/* Max-width width 768px */
@media (max-width: 767px) {
  .carousel-control-prev,
  .carousel-control-next {
    top: auto;
    bottom: 15px;
  }
  .carousel-control-prev {
    left: -5px;
  }
  .carousel-control-next {
    right: -5px;
  }

  #downloadIcons {
    margin-bottom: 2rem;
  }
  #appText p {
    font-size: 1rem;
    line-height: 1.75rem;
  }
  #appText h2 {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  #welcomeText .p-large {
    font-size: 1rem;
    width: 99% !important;
  }
  #welcomeText h4 {
    font-size: 1rem;
  }
  #welcomeText h1 {
    font-size: 1.5rem;
  }
  #welcomeContainer .col-lg-4 {
    padding-bottom: 0;
  }
  .mb-70 {
    margin-bottom: 0;
  }
  .carousel-indicators li:before {
    top: 0;
  }
  /* Testimonials */
  #testimonials {
    background: rgba(246, 249, 253, 1) !important;
  }
  .box {
    margin-bottom: 60px;
  }
  /* end of Testimonials */
  .col-lg-4 {
    padding-bottom: 1.5rem;
  }
  .basic-3 {
    padding-bottom: 0rem;
  }
  .header .img-fluid {
    display: none;
  }
  #teachersHeader .img-fluid {
    display: block;
  }
  #teachersHeader {
    background: none !important;
  }
  #teachersHeader .header-content {
    padding-top: 4rem;
    padding-bottom: 0;
  }
  #teachersFeatures .img-fluid {
    padding: 3rem;
    margin: 0;
  }
  #teachersFeatures h2 {
    font-size: 1.75rem;
  }
  #teachersFeatures .container {
    margin-top: 0 !important;
  }
  #testimonials .header-content {
    padding: 2rem 0;
  }
  #testimonials .text-container {
    margin-bottom: 0;
  }

  .footer .footer-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
  }
  .footer .footer-col h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .footer .footer-col > div > p:last-of-type {
    font-size: 0.9rem;
  }
  .footer .footer-col svg {
    display: none;
  }
  .footer .footer-col.middle {
    border-bottom: 1px solid rgba(209 209 209);
    width: 80%;
  }

  .footer .footer-col.last {
    width: 100%;
    padding-top: 2rem;
  }
  .footer .footer-col.last > div > div {
    flex-direction: row;
  }
  .footer .footer-col.last svg {
    display: inline-block;
    font-size: 1.5rem;
    margin: 0 0.8rem;
  }
  .footer .footer-col.last span {
    display: none;
  }
}
@media (max-width: 300px) {
  .carousel-control-prev,
  .carousel-control-next {
    bottom: 45px;
  }
}
